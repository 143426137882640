<template>
    <div class="center-order-table-detail clearfix">
        <!-- 图片 -->
        <el-image
            :src="orderDetail.space_img || orderDetail.goods_img || orderDetail.field_img"
            fit="scale-down"
            lazy
            class="table-detail-pic">
                <!-- 加载中样式 -->
            <template v-slot:placeholder>
                <div class="image-slot">
                    <i class="el-icon-loading"></i>
                </div>
            </template>
            <!-- 加载失败样式 -->
            <template v-slot:error>
                <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                </div>
            </template>
        </el-image>
        <!-- 信息 -->
        <div class="center-order-table-detail-text">
            <!-- 标签 -->
            <div class="center-order-table-detail-text-tabs">
                <span>{{orderDetail.style}}</span>
            </div>
            <!-- 标题 -->
            <div class="center-order-table-detail-text-title">
                {{orderDetail.space || orderDetail.goods_name || orderDetail.field_name}}
            </div>
            <!-- 下单时间 -->
            <div class="center-order-table-detail-text-time">
                <span>下单时间：</span>
                <span>{{orderDetail.orderTime}}</span>
            </div>
            <!-- 订单编号 -->
            <el-tooltip
                :content="orderDetail.orderID"
                placement="bottom-start">
                <div class="center-order-table-detail-text-num">
                    <span>订单编号：</span>
                    <span>{{orderDetail.orderID}}</span>
                </div>
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            owner: "我的订单",
        }
    },
    props: {
        orderDetail: Object
    },
    computed: mapState({
        user_id: state => state.user.user_id
    }),
}
</script>

<style scoped>
/* 图片 */
.table-detail-pic{
    float: left;
    width: 100px;
    height: 100px;
    border: 1px solid black;
}
/* 文本部分 */
.center-order-table-detail-text{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    text-align: left;
    margin-left: 110px;
}
/* 标签 */
.center-order-table-detail-text-tabs>span{
    display: inline-block;
    padding: 0 10px;
    background-color: #E6A23C;
    border-radius: 20px;
    color: #fff;
}
.center-order-table-detail-text-tabs>span+span{
    margin-left: 5px;
}
.center-order-table-detail-text-tabs>span.table-detail-text-tabs-owner{
    background-color: #8d9afc;
}
/* 标题 */
.center-order-table-detail-text-title,
.center-order-table-detail-text-num{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>